import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "40",
  height: "38",
  viewBox: "0 0 40 38",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M2.63515 8.33994C11.1699 -2.57243 25.5194 -2.98561 36.3241 8.33994C47.1288 19.6655 31.8723 39.8013 19.4788 37.2846C7.08528 34.7678 -5.62874 18.9055 2.63515 8.33994Z",
      fill: "#FFE760"
    }, null, -1),
    _createElementVNode("path", {
      d: "M11.5858 10.5858C11.2107 10.9609 11 11.4696 11 12V13C11 21.284 17.716 28 26 28H27C27.5304 28 28.0391 27.7893 28.4142 27.4142C28.7893 27.0391 29 26.5304 29 26V22.721C29.0001 22.511 28.934 22.3064 28.8112 22.136C28.6885 21.9657 28.5152 21.8383 28.316 21.772L23.823 20.274C23.5947 20.1981 23.3466 20.2071 23.1244 20.2993C22.9021 20.3915 22.7205 20.5607 22.613 20.776L21.483 23.033C19.0345 21.9267 17.0733 19.9655 15.967 17.517L18.224 16.387C18.4393 16.2795 18.6085 16.0979 18.7007 15.8756C18.7929 15.6534 18.8019 15.4053 18.726 15.177L17.228 10.684C17.1617 10.485 17.0345 10.3118 16.8644 10.189C16.6942 10.0663 16.4898 10.0002 16.28 10H13C12.4696 10 11.9609 10.2107 11.5858 10.5858Z",
      stroke: "#383733",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }